<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-row>
        <v-col cols="3">
          <v-card class="rounded-lg shadow">

            <v-card-text>
              <v-btn block depressed to="/databases" color="primary">
                <v-icon left>{{ $lang === 'ar' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
                {{ $t('btn.back') }}
              </v-btn>
            </v-card-text>


            <v-list>
              <v-list-item-group v-model="model" mandatory>

                <v-list-item color="primary"
                             class="translateY nav-list"
                             active-class="border--primary"
                             v-for="(item, i) in items"
                             :key="i"
                             :value="item.value">
                  <v-list-item-icon>
                    <v-icon color="primary" dense v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="$t(item.text)"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

          </v-card>
        </v-col>

        <v-col cols="9">
          <TypeList v-show="model===1" ref="typeList"/>
          <AreaList v-show="model===2" ref="areaList"/>
          <ClusterList v-show="model===3" ref="clusterList"/>
          <SupplyTypeList v-show="model===4" ref="spplyTypeList"/>
          <ZoneList v-show="model===5" ref="zoneList"/>
          <RouteList v-show="model===6" ref="routeList"/>
          <CircuitList v-show="model===7" ref="circuitList"/>
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>


import TypeList from "@/views/databases/config/types/TypeList";
import AreaList from "@/views/databases/config/areas/AreaList";
import ClusterList from "@/views/databases/config/clusters/ClusterList";
import SupplyTypeList from "@/views/databases/config/supplyTypes/SupplyTypeList";
import ZoneList from "@/views/databases/config/zones/ZoneList";
import RouteList from "@/views/databases/config/routes/RouteList";
import CircuitList from "@/views/databases/config/circuits/CircuitList";


export default {
  components: {CircuitList, RouteList, ZoneList, SupplyTypeList, ClusterList, AreaList, TypeList},
  data() {
    return {
      items: [
        {text: 'breadcrumb.types', icon: 'mdi-clipboard-text-outline', value: 1},
        {text: 'breadcrumb.areas', icon: 'mdi-map', value: 2},
        {text: 'breadcrumb.clusters', icon: 'mdi-ungroup', value: 3},
        {text: 'breadcrumb.spplyTypes', icon: 'mdi-clipboard-list-outline', value: 4},
        {text: 'breadcrumb.zones', icon: 'mdi-map', value: 5},
        {text: 'breadcrumb.routes', icon: 'mdi-map-marker-path', value: 6},
        {text: 'breadcrumb.circuits', icon: 'mdi-map-marker-distance', value: 7},
      ],
      model: 1,
    }
  },
  watch: {
    model(val) {
      switch (val) {
        case 1:
          return this.$refs.typeList.getItems()
        case 2:
          return this.$refs.areaList.getItems()
        case 3:
          return this.$refs.clusterList.getItems()
        case 4:
          return this.$refs.spplyTypeList.getItems()
        case 5:
          return this.$refs.zoneList.getItems()
        case 6:
          return this.$refs.routeList.getItems()
        case 7:
          return this.$refs.circuitList.getItems()
      }
    }
  },
  mounted() {
    this.$refs.typeList.getItems()
  },
  computed: {
    breadcrumbs() {
      return [
        {to: '/databases', text: 'breadcrumb.databases'},
        {to: '/config', text: 'breadcrumb.config', disabled: true},
      ]
    },
  }
}
</script>

<style scoped>
</style>