<template>
  <div>
    <v-card class="rounded-lg shadow" flat :loading="isLoading || overlay" :disabled="isLoading || overlay">

      <FormDialog item="salepointCircuits" label="Circuit" ref="formDialog" @refresh="getItems"/>

      <v-overlay :value="overlay"
                 absolute
                 :color="$vuetify.theme.dark ?'secondary':'white'">
        <v-progress-circular
            indeterminate
            size="60"
            color="primary"
        ></v-progress-circular>
      </v-overlay>

      <v-card-title>

        <v-text-field rounded filled dense
                      class="w-50"
                      v-model="search"
                      append-icon="mdi-magnify"
                      single-line hide-details clearable
                      :placeholder="$t('field.search')"/>

        <v-spacer/>

        <Refresh @refresh="getItems"/>

        <v-btn color="primary"
               rounded
               depressed
               @click="newItem">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('btn.add') }}
        </v-btn>

        &nbsp;
        <ExcelStoreDialog item="salepointCircuits" @refresh="getItems"/>

      </v-card-title>

      <v-divider/>

      <v-card-text class="pa-0">

        <v-skeleton-loader v-if="isLoading" type="table"/>

        <div v-else>

          <v-data-table
              v-if="items.length"
              :items-per-page="5"
              :no-results-text="noResultsText"
              :footer-props="footerProps"
              :headers="headers"
              :items="items"
              :search="search"
          >

            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <Delete url="salepointCircuits/delete" :id="item.id" @refresh="getItems" @overlay="overlay = $event"/>
                <Update @update="updateItem(item)"/>
              </div>
            </template>
          </v-data-table>

          <NoResult v-else size="200"/>

        </div>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import FormDialog from "@/views/databases/config/FormDialog";

export default {
  components: {FormDialog},
  data() {
    return {
      overlay: false,
      isLoading: false,
      items: [],
      search: null,
    }
  },
  methods: {
    getItems() {
      this.$Progress.start()
      this.isLoading = true
      HTTP.get('/salepointCircuits').then(res => {
        this.items = res.data.data
        this.$store.commit('api/salepointCircuits', this.items)
        this.$Progress.finish()
        this.isLoading = false
      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.isLoading = false
        console.log(err)
      })
    },
    newItem() {
      this.$refs.formDialog.open()
    },
    updateItem(item) {
      this.$refs.formDialog.open(item)
    }
  },
  computed: {
    headers() {
      return [
        {text: this.$t('field.name'), value: 'name'},
        {text: this.$t('field.description'), value: 'description'},
        {text: this.$t('field.actions'), value: 'actions', sortable: false},
      ]
    },
    footerProps() {
      return {
        itemsPerPageText: this.$t('message.itemsPerPageText'),
        itemsPerPageAllText: this.$t('message.all'),
      }
    },
    noResultsText() {
      return this.$t('message.noResultsText')
    },
  }
}
</script>

<style scoped>

</style>